/**
 * Created by GennadySX on @2020
 */

export const techlogies = [
    {
        title: 'React',
        type: 'javascript-project',
        lang: 'JavaScript',
        image: '/assets/img/card/react.png',
    },
    {
        title: 'Flutter',
        type: 'dart-project',
        lang: 'Dart',
        image: '/assets/img/card/flutter.png',
    },
    {
        title: 'Express',
        type: 'javascript-project',
        lang: 'JavaScript',
        image: '/assets/img/card/express.png',
    },
    {
        title: 'React Native',
        type: 'javascript-project',
        lang: 'JavaScript',
        image: '/assets/img/card/react-native.png',
    },
    {
        title: 'Electron',
        type: 'javascript-project',
        lang: 'JavaScript',
        image: '/assets/img/card/electron.png',
    },
    {
        title: 'Gatsby.JS',
        type: 'javascript-project',
        lang: 'JavaScript',
        image: '/assets/img/card/gatsbyjs.png',
    },
    {
        title: 'Redux / Mobx',
        type: 'javascript-project',
        lang: 'JavaScript',
        image: '/assets/img/card/redux.png',
    },
    {
        title: 'Next.JS',
        type: 'javascript-project',
        lang: 'JavaScript',
        image: '/assets/img/card/nextjs.png',
        imgClassName: 'mb-2'
    },
    {
        title: 'Nest.JS',
        type: 'javascript-project',
        lang: 'JavaScript',
        image: '/assets/img/card/nest.png',
        imgClassName: 'mb-2'
    },
    {
        title: 'Docker',
        type: 'tech-linux',
        lang: 'Linux',
        image: '/assets/img/card/docker.png',
    },
    {
        title: 'Apache Kafka',
        type: 'tech-linux',
        lang: 'Linux',
        image: '/assets/img/card/kafka.png',
        imgClassName: 'mb-2'
    },
    {
        title: 'REST / SOAP  API',
        type: 'web-tech',
        lang: 'Web',
        image: '/assets/img/card/rest.png',
    },
    {
        title: 'GraphQL  API',
        type: 'web-tech',
        lang: 'Web',
        image: '/assets/img/card/graphQL.png',
    },
    {
        title: 'Revel Framework',
        type: 'go-project',
        lang: 'Go',
        image: '/assets/img/card/go.png',
        imgClassName: 'mb-2'
    },
    {
        title: 'Ionic Framework',
        type: 'javascript-project',
        lang: 'JavaScript',
        image: '/assets/img/card/ionic.png',
    },
    {
        title: 'Vue.JS',
        type: 'javascript-project',
        lang: 'JavaScript',
        image: '/assets/img/card/vue.png',
    },
    {
        title: 'Polymer.JS',
        type: 'javascript-project',
        lang: 'JavaScript',
        image: '/assets/img/card/polymer.svg',
    },
    {
        title: 'Redis & Memcached',
        type: 'sql',
        lang: 'SQL / NoSQL',
        image: '/assets/img/card/SQL.png',
    },
    {
        title: 'MongoDB',
        type: 'sql',
        lang: 'SQL / NoSQL',
        image: '/assets/img/card/mongo.png',
    },
    {
        title: 'PostgreSQL',
        type: 'sql',
        lang: 'SQL / NoSQL',
        image: '/assets/img/card/postgres.png',
    },
    {
        title: 'MySQL',
        type: 'sql',
        lang: 'SQL / NoSQL',
        image: '/assets/img/card/mysql.png',
    },
    {
        title: 'AWS',
        type: 'tech-linux',
        lang: 'Linux',
        image: '/assets/img/card/aws.png',
        imgClassName: 'mb-2'
    },
    {
        title: 'Firebase',
        type: 'web-tech',
        lang: 'Web',
        image: '/assets/img/card/firebase.png',
    },
    {
        title: 'WebSockets',
        type: 'web-tech',
        lang: 'Web',
        image: '/assets/img/card/websocket.png',
        imgClassName: 'mb-2'
    },

]