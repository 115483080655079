/**
 * Created by GennadySX on @2020
 */

import React from 'react';
import {connect} from 'react-redux'
import HorizontalScroll from 'react-scroll-horizontal'

import '../styles/sass/portfolio.scss'
import {setLanguage} from "../redux/actions/languageAction";
import {portfolioProjects} from "../json/portfolio";

class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidMount() {
        document.body.style.overflow = 'hidden';
    }


    componentWillUnmount() {
        document.body.style.removeProperty('overflow');
    }


    render() {
        return (
            <HorizontalScroll
                pageLock={true}
                reverseScroll={true}
                style={{position: 'unset'}}
                config={{stiffness: 5, damping: 5}}
                className={'horizontal-scroll-x x portfolio-section'}
                animValues={2} >
                <section className="projects desktop-vx">
                    <div id="makeMeScrollable" className="project_area">
                        <div className="project-card wrapper">
                            {portfolioProjects.map((project, index) =>
                                <div key={index} className="card link" data-link={project.href}
                                     style={{backgroundImage: `url(${project.img})`}}>
                                    <div className="card-back"></div>
                                    <div className="card__content">
                                        <div className="card__content--description">
                                            <h3 className="roll-up">
                                                {project.title.split('').map((symbol, index) =>
                                                        <span key={index}>
                                                <span>{symbol}</span>
                                                <span
                                                    style={{color: project.title_color ? project.title_color : '#16e590'}}>{symbol}</span>
                                          </span>
                                                )}
                                            </h3>
                                            <p className="text-reveal">
                                                <span>{project.desc.map((description, index) => <span
                                                    key={index}><span>{description}</span></span>)}</span>
                                                <span>{project.desc.map((description, index) => <span
                                                    key={index}><span>{description}</span></span>)}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </HorizontalScroll>
        )
    }
}


const mappingStateToProps = (state) => {
    return {
        lang: state.lang
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLang: (lang) => dispatch(setLanguage(lang))
    }
}

export default connect(
    mappingStateToProps, mapDispatchToProps
)(Portfolio)