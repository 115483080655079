/**
 * Created by GennadySX on @2020
 */
import Home from "../views/Home";
import Contacts from "../views/Contacts";
import Portfolio from "../views/Portfolio";
import CV from "../views/CV";
import Project from "../views/Project";
import About from "../views/About";
import { Redirect } from 'react-router-dom';

export const Routes = [
    {
        path: '/about',
        component: About
    },
    {
        path: '/contacts',
        component: Contacts
    },
    {
        path: '/portfolio',
        component: Portfolio
    },
    {
        path: '/cv',
        component: CV
    },
    {
        path: '/project/:project',
        component: Project
    },
    {
        path: '/',
        component: Home
    },

]