import React from 'react';
import {Route, Switch, BrowserRouter as Router, Redirect} from 'react-router-dom';
import {connect} from 'react-redux'

import './styles/App.scss';
import {Routes} from "./routes";
import Layout from "./containers/layouts";
import {setLanguage} from "./redux/actions/languageAction";

function withLayout(WrappedComponent, route, params = null, props) {
    return class extends React.Component {
        render() {
            return (
                <Layout {...props}>
                    <WrappedComponent user={JSON.parse(localStorage.getItem('user'))} params={params ? params : null}/>
                </Layout>
            )
        }
    };
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                    <Router>
                        <Switch>
                            <Route exact path="/project" render={() => (<Redirect to="/portfolio" />)} />
                            {Routes.map((route, idx) =>
                                <Route path={route.path} component={withLayout(route.component, route.path, this.state, this.props)}
                                       key={idx}/>
                            )}
                        </Switch>
                    </Router>
            </React.Fragment>
        )
    }
}


const mappingStateToProps = (state) => {
    return {
        lang: state.lang
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLang: () => dispatch(setLanguage())
    }
}

export default connect(mappingStateToProps, mapDispatchToProps)(App)
