/**
 * Created by GennadySX on @2020
 */

import {createStore, applyMiddleware, compose} from 'redux';
import reducers from '../reducers';


export const store = createStore(
    reducers
);

