/**
 * Created by GennadySX on @2020
 */

import React from 'react';
import {Link} from "react-router-dom";
import $ from 'jquery'
import AOS from 'aos'
import SmoothScroll from 'smooth-scroll'

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidMount() {
        this.aosAnimate()
    }



    aosAnimate() {
        setInterval(function () {
            AOS.init({
                duration: 1500,
                easing: 'ease-out-quart',
                once: true
            });
        }, 1700);

        setInterval(function () {
            $('.upper-block').remove();
        }, 4000);
        const docSize = $(window).innerWidth();

        if (docSize >= 320 && docSize <= 1200) {
            $('.desktop-v').remove();
            $('body').css("overflow", "auto");

        } else {
            if (window.location.pathname === '/portfolio' || window.location.pathname === '/portfolio.html') {


                new SmoothScroll("div#makeMeScrollable",{
                    hotSpotScrolling: false,
                    visibleHotSpotBackgrounds: "",
                    mousewheelScrollingStep: 75,
                    mousewheelScrolling: "allDirections",
                    touchScrolling: false
                });
                console.log(docSize);
            }
        }
    }

    render() {
        return (
            <> </>
        )
    }


}




export default Footer