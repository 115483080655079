/**
 * Created by GennadySX on @2020
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Header from "./header";
import Footer from "./footer";


class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {};

    }


    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <Header {...this.props}/>
                {this.props.children}
                <Footer/>
            </React.Fragment>
        );
    }
}


export default withRouter(Layout);