/**
 * Created by GennadySX on @2020
 */

import React from 'react';

import {Link} from "react-router-dom";
import $ from 'jquery'
import AOS from 'aos'
import {TweenLite} from "gsap/all";
import {gsap} from "gsap";
import {withRouter} from 'react-router-dom'
import {setLanguage} from "../../redux/actions/languageAction";
import {lang} from "../../lang";
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mouseShowed: true
        }

        gsap.registerPlugin(TweenLite)

    }


    componentDidMount() {
      this.styled()
        //console.log('lang code is', this.props.lang.code)
    }



    styled() {
        this.preloadIt()
        this.pageTransition()
        this.tiltCard()
        this.mouse()
    }

    mouse() {
        window.addEventListener('mousemove', e =>
            Promise.all([
                TweenLite.to($('.circle-cursor--inner'), this.state.mouseShowed ? 0 : 0.3, {
                    x: e.clientX,
                    y: e.clientY,
                }),
                TweenLite.to($('.circle-cursor--outer'), this.state.mouseShowed ? 0 : 0.7, {
                    x: e.clientX,
                    y: e.clientY,
                })
            ]).then(() => this.state.mouseShowed ?
                    this.setState({mouseShowed: false}, () => $('.circle-cursor').css('display', 'block'))
                    : null))


        $("a, button").hover(this.hoverFunc, this.unhoverFunc);
        $('body').css("cursor", 'none')
    }


    hoverFunc() {
        TweenLite.to($('.circle-cursor--inner'), 0.3, {
            opacity: 1,
            scale: 0,
        });
        TweenLite.to($('.circle-cursor--outer'), 0.3, {
            scale: 2,
        });
    }

    unhoverFunc() {
        TweenLite.to($('.circle-cursor--inner'), 0.3, {
            opacity: 1,
            scale: 1,
        });
        TweenLite.to($('.circle-cursor--outer'), 0.3, {
            scale: 1,
        });
    }

    preloadIt() {
        let  perfData = window.performance.timing,
            EstimatingTime = -(perfData.loadEventEnd - perfData.navigationStart),
            time = parseInt((EstimatingTime/1000)%60)*100;

            let star = '<div class="container"><div class="star"></div></div>';
            const line = '<div class="loader__tile"></div>';
            const  inx  = $('.loader');
            const  starts = $(".starts");

            for(var i =0; i < 50; i++) {
                starts.append($(star));
                inx.append($(line));
            }

            function readyPage() {
                $(".loading-page .counter").fadeOut(500, function(){
                    $('.loading-page').remove();
                    let loader = $('.loader');
                    loader.removeClass('loader--active');
                    setInterval(() => {
                        AOS.init({
                            duration: 800,
                            easing: 'ease-out-quart',
                            once: true
                        });
                        $('.content-main-1').fadeIn(1000);
                    }, 1300);
                });
            }

            String.prototype.times = function(n) {
                return Array.prototype.join.call({length: n+1}, this);
            };

            star = '<div class="container"><div class="star"></div></div>'.times(52);
            $('.stars').append(star);

            this.preloader(time, readyPage);
    }

    preloader(time, readyPage) {

            let counter = 0,
            c = 0,
             i = setInterval(function(){
                $(".loading-page .counter h1").html(c + "%");
                $(".loading-page .counter hr").css("width", c + "%");
                $(".loading-page .counter h1.color").css("width", c + "%");
                counter++;
                c++;
                if(counter === 101) {
                    clearInterval(i);
                    readyPage();
                }
            }, time.parseInt);
    }

    pageTransition(props = this.props) {
            $.fn.mPageTransition = function(options) {
                // Grab the settings
               let settings = $.extend({
                    color: "#000",
                    fadeOutTime: 300,
                    fadeInTime: 300
                }, options);
               let body = $('body');
               let originalBackgroundColor = $(body).css("background-color");
                $(body).css('visibility','visible').hide().fadeIn(settings.fadeInTime);
                $("a").not('.disabled').click(function(e) {
                    // Get the original link location and stop it from occuring
                   let link = this;
                    e.preventDefault();
                    $(body).animate({
                        "opacity": 1
                    }, settings.fadeInTime, function() {
                        $(body).animate({"background-color": originalBackgroundColor}, settings.fadeOutTime, function() {
                           let href = $(link).attr("href");
                            let loader =$('.loader');
                            const blank = $(link).attr('data-blank')

                            loader.addClass('loader--active');
                            setTimeout(function () {
                                 loader.removeClass('loader--active');
                                if (typeof href !== 'undefined') {
                                    if (href.toString().indexOf('http') >= 0 || blank ) {
                                        window.open( href, '_blank')
                                    } else {
                                        props.history.push(href)
                                    }
                                }
                            }, 1500);
                        });
                    });
                });

                $(".card.link").not('.disabled').click(function(e) {
                    // Get the original link location and stop it from occuring
                    let link = this;
                    e.preventDefault();
                    $(body).animate({
                        "opacity": 1
                    }, settings.fadeInTime, function() {
                        $(body).animate({"background-color": originalBackgroundColor}, settings.fadeOutTime, function() {
                            let href = $(link).attr("data-link");
                            let loader =$('.loader');
                            loader.addClass('loader--active');
                            setTimeout(function () {
                                loader.removeClass('loader--active');
                                if (typeof href !== 'undefined') {
                                    if (href.toString().indexOf('http') >= 0 ) {
                                        window.open( href, '_blank')
                                    } else {
                                        props.history.push(href)
                                    }
                                }
                            }, 1500);
                        });
                    });
                });
            };
        $('body').mPageTransition();
    }

    tiltCard() {
        require('tilt.js')
      /*  $(".card").tilt({
            glare: true,
            maxGlare: 0.150,
            maxTilt: 5
        });*/
        $(".card").tilt({
            glare: true,
            maxGlare: .5,
            maxTilt: 5
        })
    }


    changeLang() {
        this.props.setLang()
        //console.log('lang changed', this.props.lang.code)
    }


    render() {
        return (
            <>
                <div className="load ">
                    <div className="circle-cursor circle-cursor--inner"> </div>
                    <div className="circle-cursor circle-cursor--outer"> </div>
                    <div className="stars"> </div>
                    <div className="loader loader--active"> </div>
                    <div className="loading-page ">
                        <div className="counter">
                            <p>{lang('main.loading')}</p>
                            <h1>0%</h1>
                            <hr/>
                        </div>
                    </div>
                </div>

                <header className="header">
                    <nav className="navbar navbar-expand-xl navbar-dark">
                        <div className="container-fluid">
                            <div className="navbar-header icon">
                                <Link className="navbar-brand" to="#">.SX</Link>
                            </div>
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                                    data-target="#navbarsExample06"
                                    aria-controls="navbarsExample06" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"> </span>
                            </button>

                            <div className="navbar-collapse collapse" id="navbarsExample06">
                                <ul className="navbar-nav ml-auto">
                                    <li className={this.props.match.path === '/' ? "nav-item active" : 'nav-item'}>
                                        <Link className={this.props.match.path === '' ? "nav-link disabled" : 'nav-link'} to="/">{lang('header.main')}<span
                                            className="sr-only">(current)</span></Link>
                                    </li>
                                    <li  className={this.props.match.path === '/about' ? "nav-item active" : 'nav-item'}>
                                        <Link className={this.props.match.path === '/about' ? "nav-link disabled" : 'nav-link'} to="/about">{lang('header.about')}</Link>
                                    </li>
                                    <li  className={this.props.match.path === '/portfolio' ? "nav-item active" : 'nav-item'}>
                                        <Link className={this.props.match.path === '/portfolio' ? "nav-link disabled" : 'nav-link'}  to="/portfolio">{lang('header.portfolio')}</Link>
                                    </li>
                                {/*    <li  className={this.props.match.path === '/cv' ? "nav-item active" : 'nav-item'}>
                                        <Link className={this.props.match.path === '/cv' ? "nav-link disabled" : 'nav-link'} to="/cv">{lang('header.cv')}</Link>
                                    </li>*/}
                                    <li className={this.props.match.path === '/contacts' ? "nav-item active" : 'nav-item'}>
                                        <Link className={this.props.match.path === '/contacts' ? "nav-link disabled" : 'nav-link'} to="/contacts">{lang('header.contacts')}</Link>
                                    </li>
                                    <li className="nav-item">
                                         <span className="switcher switcher-1">
                                           <input type="checkbox" id="switcher-1" checked={this.props.lang.code === 'EN' ? true : false} onChange={() => this.changeLang()}  />
                                           <label htmlFor="switcher-1" > </label>
                                        </span>
                                    </li>


                                </ul>

                            </div>
                        </div>
                    </nav>
                </header>
            </>
        )
    }
}


export default withRouter(Header)
