/**
 * Created by GennadySX on @2020
 */

import React from 'react';
import {Link} from "react-router-dom";
import $ from "jquery";
import {lang} from "../lang";
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidMount() {
        this.avatarScale()
        document.body.style.overflow = 'hidden';
    }



    avatarScale() {

            // Init
            let container = document.getElementById("area"),
                inner = document.getElementById("element");

            // Mouse
            let mouse = {
                _x: 0,
                _y: 0,
                x: 0,
                y: 0,
                updatePosition: function(event) {
                    let e = event || window.event;
                    this.x = e.clientX - this._x;
                    this.y = (e.clientY - this._y) * -1;
                },
                setOrigin: function(e) {
                    this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
                    this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
                },
                show: function() {
                    return "(" + this.x + ", " + this.y + ")";
                }
            };

            // Track the mouse position relative to the center of the container.
            mouse.setOrigin(container);

            //-----------------------------------------

            let counter = 0;
            let updateRate = 10;
            let isTimeToUpdate = function() {
                return counter++ % updateRate === 0;
            };

            //-----------------------------------------

            let onMouseEnterHandler = function(event) {
                update(event);
            };

            let onMouseLeaveHandler = function() {
                inner.style = "";
            };

            let onMouseMoveHandler = function(event) {
                if (isTimeToUpdate()) {
                    update(event);
                }
            };

            //-----------------------------------------

            let update = function(event) {
                mouse.updatePosition(event);
                if (inner.offsetHeight !== null) {
                    updateTransformStyle((mouse.y / inner.offsetHeight / 2).toFixed(2),
                        (mouse.x / inner.offsetWidth / 2).toFixed(2)      );
                }
            };

            let updateTransformStyle = function(x, y) {
                let style = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
                inner.style.transform = style;
                inner.style.webkitTransform = style;
                inner.style.mozTransform = style;
                inner.style.msTransform = style;
                inner.style.oTransform = style;
            };
            //-----------------------------------------
            container.onmouseenter = onMouseEnterHandler;
            container.onmouseleave = onMouseLeaveHandler;
            container.onmousemove = onMouseMoveHandler;
    }

    componentWillUnmount() {
        document.body.style.removeProperty('overflow');
    }

    render() {
        return (
            <>
                <section id="area">
                    <div className="containerx">
                        <div className="icon-sx vertical-icons">
                            <ul>
                                <li>
                                    <Link to="https://facebook.com/GennadySX" data-blank={true}><i className="fab fa-facebook-square"> </i></Link>
                                </li>
                                <li>
                                    <a href="https://twitter.com/GennadySX" data-blank={true}>
                                    <i className="fab fa-twitter-square"> </i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/in/GennadySX" data-blank={true}><i className="fab fa-linkedin "> </i></a>
                                </li>
                                <li><a href="skype:live:gennadysx?call" data-blank={true}><i className="fab fa-skype"> </i></a></li>
                                <li><a  href="https://github.com/GennadySX" data-blank={true}><i className="fab fa-github "> </i></a></li>
                            </ul>
                        </div>
                        <div className="col-md-10 main-content">

                            <div className="content-main-1">
                                <div className="content-center" id="element">
                                    <img src={'/assets/img/y-img.jpg'} alt=""
                                        /* className={'xtype-0'}*/
                                    />
                                </div>
                            </div>
                            <div className=" main_text">

                                <h1>
                                    <div className="reveal-holder" data-aos="reveal-item">
                                        <div className="reveal-block yellow right" data-aos="reveal-right"> </div>
                                        {lang('main.hi')}
                                    </div>
                                    <div className="reveal-holder" data-aos="reveal-item">
                                        <div className="reveal-block blue bottom" data-aos="reveal-bottom"> </div>
                                        Gennady
                                    </div>
                                    <div className="reveal-holder" data-aos="reveal-item">
                                        <div className="reveal-block left" data-aos="reveal-left"> </div>
                                        Sabirovsky 
                                    </div>
                                </h1>
                                <h2>
                                    <a href="https://en.wikipedia.org/wiki/software_engineer"
                                       target="_blank">
                                    <div className="reveal-holder" data-aos="reveal-item">
                                        <div className="reveal-block gray top" data-aos="reveal-top"> </div>
                                        #{lang('main.software_engineer')}
                                    </div>
                                </a></h2>
                               {/* <button className="btn-hire">{lang('main.hire')}</button>*/}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }


}




export default Home