/**
 * Created by GennadySX on @2020
 */

export const PROJECT_CURRENT = 'PROJECT_CURRENT'


export const setProject = (project) => ({
    type: PROJECT_CURRENT,
    project
})