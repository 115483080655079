/**
 * Created by GennadySX on @2020
 */
import {SET_LANG} from "../actions/languageAction";

const isLang = (lang = null) => {
    const getLang = lang ? lang : localStorage.getItem('language')
   return  getLang === 'EN' ? set("RU") : set('EN');
}

const set = (code) => {
    localStorage.setItem('language', code);
    return code
}

const initial = {
    code: localStorage.getItem('language')  ?  localStorage.getItem('language') : 'RU'
}

const languageReducer = (state = initial, action) => {
    switch (action.type) {
        case SET_LANG:
            return {
                ...state,
                code: isLang(state.code)
            }
        default:
            return state;
    }
}


export default languageReducer;