/**
 * Created by GennadySX on @2020
 */

export const portfolioProjects = [
    {
        title: 'Зилант',
        title_color: '#16e590',
        desc: [
            'Склад техники',
            'для строительства',
            'и большие предпринимателей'
        ],
        img: '/assets/img/project/zilant-logo.png',
        href: '/project/zilant'
    },
    {
        title: 'MySupl',
        title_color: '#FF7043',
        desc: [
            'Клуб компании',
            'строительств',
        ],
        img: '/assets/img/project/mysupl.jpg',
        href: '/project/mysupl'
    },
    {
        title: 'Сонотек',
        title_color: '#00a33b',
        desc: [
            'Звукоизоляция',
            'и акустика помещений',
        ],
        img: '/assets/img/project/sano.jpg',
        href: '/project/sonotek'
    },
    {
        title: 'Донские-зори',
        title_color: '#7C7B7B',
        desc: [
            'Кирпич ручной формовки',
            'в городе Казани',
        ],
        img: '/assets/img/project/don-ski.png',
        href: '/project/donski-zori'
    },


]