/**
 * Created by GennadySX on @2020
 */
import { combineReducers } from 'redux';
import projectReducer from "./projectReducer";
import languageReducer from "./languageReducer";


const rootReducer = combineReducers({
    project: projectReducer,
    lang: languageReducer
})


export default rootReducer