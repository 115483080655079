/**
 * Created by GennadySX on @2020
 */

import React from 'react';
import {lang} from "../lang";
import '../styles/sass/cv.scss'
class CV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidMount() {
        document.body.style.overflow = 'hidden';
    }
    componentWillUnmount() {
        document.body.style.removeProperty('overflow');
    }

    render() {
        return (
            <>
                <section className="cv desktop-v">
                    <div id="makeMeScrollable" className="project_areax">
                        <div className="project-card wrapper">
                            <div className="card link"
                                 style={{backgroundImage: "url('/assets/img/cv/ru.jpg')"}}>
                                <div className="card-back"></div>
                                <div className="card__content">
                                    <div className="user-content">
                                        <div className="button-area">
                                            <button className="btn print btn-warning"><i
                                                className="fa fa-print"></i> Печать
                                            </button>
                                            <a href={window.location.href+'/ru.pdf'} download className="btn text-light download btn-success"><i
                                                className="fa fa-download"></i> Скачать
                                            </a>
                                            <button className="btn view "><i className="fa fa-eye"></i> Посмотреть
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card__content--description">
                                        <h3 className="roll-up cv-ru">
                                            <span><span>R</span><span>R </span></span>
                                            <span><span>U</span><span>U</span></span>
                                        </h3>

                                    </div>
                                </div>
                            </div>
                            <div className="card link"
                                 style={{backgroundImage: "url('/assets/img/cv/en.jpg')"}}>
                                <div className="card-back"></div>
                                <div className="card__content">
                                    <div className="user-content">
                                        <div className="button-area">
                                            <button className="btn print  btn-warning"><i
                                                className="fa fa-print"></i> Print
                                            </button>
                                            <a  href={window.location.href+'/en.pdf'} className="btn text-light download btn-success"><i
                                                className="fa fa-download"></i> Download
                                            </a>
                                            <button className="btn view"><i className="fa fa-eye"></i> View</button>
                                        </div>
                                    </div>
                                    <div className="card__content--description">
                                        <h3 className="roll-up cv-eng">
                                            <span><span>E</span><span>E</span></span>
                                            <span><span>N</span><span>N</span></span>
                                        </h3>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="mobile-v ">
                    <div className="project-card-x wrapper-x cv-page">
                        <div className="card link"
                             style={{backgroundImage: "url('/assets/img/cv/ru.jpg')"}}>
                            <div className="card-back"></div>
                            <div className="card__content">
                                <div className="user-content">
                                    <div className="button-area">
                                        <button className="btn print btn-warning"><i className="fa fa-print"></i> Печать
                                        </button>
                                        <button className="btn download btn-success"><i
                                            className="fa fa-download"></i> Скачать
                                        </button>
                                        <button className="btn view "><i className="fa fa-eye"></i> Посмотреть</button>
                                    </div>
                                </div>
                                <div className="card__content--description">
                                    <h3 className="roll-up cv-ru">
                                        <span><span>R</span><span>R </span></span>
                                        <span><span>U</span><span>U</span></span>
                                    </h3>

                                </div>
                            </div>
                        </div>
                        <div className="card link"
                             style={{backgroundImage: "url('/assets/img/cv/en.jpg')"}}>
                            <div className="card-back"></div>
                            <div className="card__content">
                                <div className="user-content">
                                    <div className="button-area">
                                        <button className="btn print  btn-warning"><i className="fa fa-print"></i> Print
                                        </button>
                                        <button className="btn download btn-success"><i
                                            className="fa fa-download"></i> Download
                                        </button>
                                        <button className="btn view"><i className="fa fa-eye"></i> View</button>
                                    </div>
                                </div>
                                <div className="card__content--description">
                                    <h3 className="roll-up cv-eng">
                                        <span><span>E</span><span>E</span></span>
                                        <span><span>N</span><span>N</span></span>
                                    </h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }


}




export default CV