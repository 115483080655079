/**
 * Created by GennadySX on @2020
 */

export const projects = {
    zilant: {
        title: 'ООО "Зилант"',
        desc: 'Группа компании',
        href: 'https://zilant.market',
        href_title: 'zilant.market',
        type: 'Интернет магазин',
        develop_role: 'Full-Stack',
        images: [
            '/assets/img/project/zilant.png',
            '/assets/img/project/zilant-s.png',
        ]
    },
    mysupl: {
        title: 'ООО "MySupl"',
        desc: 'КЛУБ КОМПАНИИ СТРОИТЕЛЬСТВ',
        href: 'https://mysupl.ru',
        href_title: 'mysupl.ru',
        type: 'Торговая площадка',
        develop_role: 'BACK-END',
        images: [
            '/assets/img/project/mysupl-project.png',
            '/assets/img/project/mysupl-project-second.png',
            '/assets/img/project/mysupl-project-third.png',
        ]
    },
    sanotek: {
        title: 'СОНОТЕК',
        desc: 'ЗВУКОИЗОЛЯЦИЯ\n' +
            'И АКУСТИКА ПОМЕЩАНИЙ',
        href: '',
        href_title: '',
        type: 'ИНТЕРНЕТ МАГАЗИН',
        develop_role: 'BACK-END',
        images: [
            '/assets/img/project/sonotek-project.png',
            '/assets/img/project/sonotek-project-second.png',
            '/assets/img/project/sonotek-project-third.png',
        ]
    },
    "donski-zori": {
        title: 'ДОНСКИЕ-ЗОРИ',
        desc: 'КИРПИЧ РУЧНОЙ ФОРМОВКИ',
        href: 'https://xn----gtbdjcdjxigwf.xn--p1ai/',
        href_title: 'ДОНСКИЕ-ЗОРИ.РФ',
        type: 'Интернет магазин',
        develop_role: 'BACK-END',
        images: [
            '/assets/img/project/don-project.png',
            '/assets/img/project/don-project-second.png',
            '/assets/img/project/don-project-third.png',
        ]
    },
    'essen': {
        title: '',
        desc: '',
        href: '',
        href_title: '',
        type: '',
        develop_role: '',
        images: [
            '',
            '',
        ]
    },
    'vanya-gog': {
        title: '',
        desc: '',
        href: '',
        href_title: '',
        type: '',
        develop_role: '',
        images: [
            '',
            '',
        ]
    },
    'totup': {
        title: '',
        desc: '',
        href: '',
        href_title: '',
        type: '',
        develop_role: '',
        images: [
            '',
            '',
        ]
    },
    'ests': {
        title: '',
        desc: '',
        href: '',
        href_title: '',
        type: '',
        develop_role: '',
        images: [
            '',
            '',
        ]
    },
    'isdiploma': {
        title: '',
        desc: '',
        href: '',
        href_title: '',
        type: '',
        develop_role: '',
        images: [
            '',
            '',
        ]
    },
    'edubrains': {
        title: '',
        desc: '',
        href: '',
        href_title: '',
        type: '',
        develop_role: '',
        images: [
            '',
            '',
        ]
    },
    'timcrm': {
        title: '',
        desc: '',
        href: '',
        href_title: '',
        type: '',
        develop_role: '',
        images: [
            '',
            '',
        ]
    },
    'spectehnikoff': {
        title: '',
        desc: '',
        href: '',
        href_title: '',
        type: '',
        develop_role: '',
        images: [
            '',
            '',
        ]
    },
    'adsme': {
        title: '',
        desc: '',
        href: '',
        href_title: '',
        type: '',
        develop_role: '',
        images: [
            '',
            '',
        ]
    },
    'floda': {
        title: '',
        desc: '',
        href: '',
        href_title: '',
        type: '',
        develop_role: '',
        images: [
            '',
            '',
        ]
    },
}