/**
 * Created by GennadySX on @2020
 */

const english = {
    'main.loading': 'Loading',
    ///header menu
    'header.main': "MAIN",
    'header.about': "ABOUT ME",
    'header.portfolio': "PORTFOLIO",
    'header.cv': "CV",
    'header.contacts': "CONTACTS",


    'main.hi': 'Hi, I am ',
    'main.software_engineer': 'Software Engineer',
    'main.hire': 'Hire me',


    //About

    'about.gennady': "Геннадий",
    'about.sabirovsky': "Sabirovsky",
    'about.about_me': "About me",
    'about.about_note': 'Hi, My name is Gennady Sabirovsky. ' +
        'I am 23 years old. I from Uzbekistan and now live in Russia. ' +
        'I started developing almost 5 years ago and on the during time i learned much IT technology ' +
        'and developing mobile apps as like for OS (Android, IOS). I usally develop systems for corporation ' +
        'websites as like CRM, EduSystems and e-commerce.',
    'about.skills': 'Skills',
    'about.technologies': 'Technologies',

    //Contacts
    'contacts.contacts': 'Contacts',
    'contacts.say_hello': 'Say Hello!',
    'contacts.your_name': 'Your name',
    'contacts.your_number': 'Phone number',
    'contacts.your_email': 'Email',
    'contacts.your_message': 'Message...',
    'contacts.send': 'Send',
    'contacts.find_on': 'you can find me on:',
    'contacts.kazan': 'Kazan',
    'contacts.russia': 'Russia',
    'contacts.developed_by': '@2019. Designed and developed by',
    'contacts.gennadysx': 'GennadySX',

}

export {english}