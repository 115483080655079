/**
 * Created by GennadySX on @2020
 */
import {PROJECT_CURRENT} from "../actions/projectAction";

const initial = {
    project: null
}


const projectReducer = (state = initial, action) => {
    switch (action.type) {
        case PROJECT_CURRENT:
            return {
                ...state,
                project: action.project
            }
        default:
            return state;
    }
}


export default projectReducer;