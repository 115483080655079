/**
 * Created by GennadySX on @2020
 */

const russian = {
    'main.loading': 'Загружается',

    ///header menu
    'header.main': "ГЛАВНАЯ",
    'header.about': "ОБО МНЕ",
    'header.portfolio': "ПОРТФОЛИО",
    'header.cv': "РЕЗЮМЕ",
    'header.contacts': "КОНТАКТЫ",

    'main.hi': 'Привет, я ',
    'main.software_engineer': 'Программист инженер',
    'main.hire': 'Нанимать',

    //About

    'about.gennady': "Геннадий",
    'about.sabirovsky': "Сабировский",
    'about.about_me': "Обо мне",
    'about.about_note': 'Привет, меня зовут Геннадий Сабировский. Мне 23. Я из Узбекистана и сейчас живу в России. Я начал разрабатывать почти 5 лет назад, и за это время я научился многим IT-технологиям и разрабатывал мобильные приложения, как для ОС (Android, IOS). Я обычно разрабатываю системы для корпоративных сайтов, такие как CRM, EduSystems и электронная коммерция.',
    'about.skills': 'Навыки',
    'about.technologies': 'Технологии',

    //Contacts
    'contacts.contacts': 'Контакты',
    'contacts.say_hello': 'Свяжитесь!.',
    'contacts.your_name': 'Ваша имя',
    'contacts.your_number': 'Номер',
    'contacts.your_email': 'E-mail',
    'contacts.your_message': 'Сообщение...',
    'contacts.send': 'Отправить',
    'contacts.find_on': 'Вы можете найти меня на:',
    'contacts.kazan': 'Казань',
    'contacts.russia': 'Россия',
    'contacts.developed_by': '@2019. Сайт разработан от ',
    'contacts.gennadysx': 'GennadySX',


}


export {russian}