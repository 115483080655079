/**
 * Created by GennadySX on @2020
 */

import React from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import '../styles/sass/project.scss'
import $ from 'jquery'
import {getProject} from "../helpers/getProject";
class Project extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: getProject(this.props.match.params.project)
        }
        this.check()
    }


    componentDidMount() {
        $('.navbar-dark').addClass('bg-black')
        $('header').css('z-index', 105)
    }

    check() {
      if (!this.state.project)
          this.props.history.push('/portfolio')
    }


    componentWillUnmount() {
        $('.navbar-dark').removeClass('bg-black')
        $('header').css('z-index', 999)
    }

    render() {
        const {project} = this.state
        return (
           project ?
            <>
                <section className="project-area project-section">
                    <div className="left-col">

                        <div className="logo"> </div>

                        <div className="year">2018-2019</div>

                        <div className="cover">
                            <div className="img">
                                {project.images.map((img, index) =>
                                    <img key={index} src={img}  alt={project.title} />
                                )}

                            </div>
                        </div>

                        <div className="data">
                        </div>
                        <div className="block-cover"></div>
                    </div>

                    <div className="right-col">
                        <div className="upper">
                            <div className="about">
                                <h1>{project.title}</h1>
                                <div className="album-name2">
                                    <p>
                                        <i className="fa fa-bookmark"></i>
                                        &nbsp;<span>{project.desc}</span></p>
                                </div>
                                <div className="album-name2">
                                    <p><a href={project.href} target="_blank">
                                        <i className="fa fa-globe"></i>
                                        &nbsp;<span>{project.href_title}</span></a></p>
                                </div>
                                <div className="album-artist2">
                                    <p>
                                        <i className="fa fa-briefcase"></i>
                                        &nbsp;<span>{project.type}</span></p>
                                </div>
                                <div className="media">
                                    <p>Разработка сайта: {project.develop_role}</p>
                                </div>
                            </div>
                        </div>
                        <div className="upper-block"></div>
                    </div>
                </section>
            </> : null
        )
    }


}



export default connect()(withRouter(Project))