/**
 * Created by GennadySX on @2020
 */

import React from 'react';
import {techlogies} from "../json/technologies";
import {lang} from "../lang";


class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidMount() {
    }


    render() {
        return (
            <div className={'about-body'}>
                <section className="area-about col-md-12">
                    <div className="container column-full">
                        <div className="text-content ">
                            <div className="reveal-holder" data-aos="reveal-item">
                                <div className="reveal-block right" data-aos="reveal-right"></div>
                                <h1 className="name">{lang('about.about_me')}</h1>
                            </div>
                            <div className="reveal-holder" data-aos="reveal-item">
                                <div className="reveal-block left" data-aos="reveal-left"></div>
                                <p className="about-me">{lang('about.about_note')}</p>
                            </div>

                        </div>
                        <div className="image-content reveal-holder" data-aos="reveal-item">
                            <div className="reveal-block top extra" data-aos="reveal-top" style={{zIndex: 99}}></div>
                            <img src="/assets/img/x-img.jpg" alt="" srcSet=""/>
                        </div>
                        <div className="sub">
                            <h1 className="title">{lang('about.skills')}</h1>
                            <div className="skills">

                                <div className="progress-bar-container skill web">
                                    <div className="progress-bar stripes">
                                          <span className="progress-bar-inner">
                                            <p className="percentage">HTML / CSS3</p>
                                          </span>
                                    </div>
                                </div>
                                <div className="progress-bar-container skill javascript">
                                    <div className="progress-bar stripes">
                                          <span className="progress-bar-inner">
                                            <p className="percentage">JavaScript / TS</p>
                                          </span>
                                    </div>
                                </div>

                                <div className="progress-bar-container skill swift">
                                    <div className="progress-bar stripes">
                                          <span className="progress-bar-inner">
                                            <p className="percentage">Swift</p>
                                          </span>
                                    </div>
                                </div>
                                <div className="progress-bar-container skill java">
                                    <div className="progress-bar stripes">
                                      <span className="progress-bar-inner">
                                        <p className="percentage">Java</p>
                                      </span>
                                    </div>
                                </div>

                                <div className="progress-bar-container skill go">
                                    <div className="progress-bar stripes">
                                          <span className="progress-bar-inner">
                                            <p className="percentage">Go</p>
                                          </span>
                                    </div>
                                </div>
                                <div className="progress-bar-container skill dart">
                                    <div className="progress-bar stripes">
                                      <span className="progress-bar-inner">
                                        <p className="percentage">Dart </p>
                                      </span>
                                    </div>
                                </div>

                                <div className="progress-bar-container skill sql">
                                    <div className="progress-bar stripes">
                                        <span className="progress-bar-inner">
                                          <p className="percentage text-dark">SQL / NoSQL</p>
                                        </span>
                                    </div>
                                </div>
                                <div className="progress-bar-container skill linux">
                                    <div className="progress-bar stripes">
                                          <span className="progress-bar-inner">
                                            <p className="percentage">Linux / Unix</p>
                                          </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="second-block col-md-12">
                    <h1 className="title">{lang('about.technologies')}</h1>
                    <div className="tech-cards container">
                        <div className="row">
                            {techlogies.map((item, index) =>
                                <div className="col-sm-3 card-full " key={index}>
                                <div className={`card ${item.type}`}>
                                    <div className="card-body">
                                        <img src={item.image} alt="" className={item.imgClassName}/>
                                        <h5 className="card-title">{item.title}</h5>
                                        <a href="#" className="btn btn-dark">{item.lang}</a>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                    <div className="stars"></div>
                </section>
                <section className="third-block col-md-12">
                    <div className="container">
                        <div className="row">
                            <ul className="interests">
                                <li className="ns"><a href="https://soundcloud.com/GennadySX">.soundcloud</a></li>
                                <li className="ns"><a href="mailto:gennadysx@outlook.com" data-blank={true}>.outlook</a></li>
                                <li className="ns"><a href="https://linkedin.com/in/GennadySX">.ln</a></li>
                                <li className="ns"><a href="https://github.com/GennadySX">.github</a></li>
                                <li className="ns"><a href="https://medium.com/@gennadySX">.medium</a></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


export default About