/**
 * Created by GennadySX on @2020
 */

import React from 'react';
import {lang} from "../lang";


class Contacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidMount() {

    }
    componentWillUnmount() {
        document.body.style.removeProperty('overflow');
    }
    render() {
        return (
            <>
                <section className="contact-area col-md-12">
                    <div className="container">
                        <div className="top-content">
                            <h1>{lang('contacts.contacts')}</h1>
                            <span className="forward"></span>
                        </div>
                        <div className="bottom-area">
                            <h1 className="title">{lang('contacts.say_hello')}</h1>
                            <div className="form col-md-11">
                                <form action="">
                                    <input type="text" name="name" placeholder={lang('contacts.your_name')} />
                                        <input type="text" name="phone" placeholder={lang('contacts.your_number')} />
                                            <input type="text" name="email" placeholder={lang('contacts.your_email')}/>
                                                <textarea name="message" id="" cols="30" rows="7" style={{resize: 'none'}}
                                                          placeholder={lang('contacts.your_message')}></textarea>
                                                <button type="submit">{lang('contacts.send')}</button>
                                </form>
                            </div>

                        </div>

                    </div>

                </section>
                <section className="third-block col-md-12 contact-version">
                    <div className="container">
                        <div className="row">
                            <h1 className="title-footer">{lang('contacts.find_on')}</h1>
                            <ul className="interests">
                                <li className="ns phone"><a href="tel:+79991571858" data-blank={true}><span className="dot">.</span>phoneNumber</a>
                                </li>
                                <li className="ns email"><a href="mailto:the@gennadysx.com" data-blank={true}><span
                                    className="dot">.</span>email</a></li>
                                <li className="ns vk"><a href="https://vk.com/GennadySX"><span className="dot">.</span>vk</a>
                                </li>
                                <li className="ns facebook"><a href="https://facebook.com/GennadySX"><span
                                    className="dot">.</span>fb</a></li>
                                <li className="ns tvitter"><a href="https://twitter.com/@gennadySX"><span
                                    className="dot">.</span>tw</a></li>
                            </ul>
                            <div className="info">
                                <span className="address">{lang('contacts.kazan')}, {lang('contacts.russia')}</span>
                                <br/>
                            </div>
                        </div>
                        <span className="made_by">{lang('contacts.developed_by')}<a
                            href="https://linkedin.com/in/GennadySX">{lang('contacts.gennadysx')}</a>.</span>
                    </div>
                </section>
            </>
        )
    }


}




export default Contacts